body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
header {
  display: block;
  padding-left: 20px;
  padding-top: 20px;
}
.header-icon {
  height: 65px;
}
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: 0.5s ease-in;
}
.modal-open {
  opacity: 1;
}
.modal-closed {
  opacity: 0;
}
.effect-img {
  width: 50%;
}
.form-image {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.radio-buttons {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.radio-buttons .btn-span {
  display: block;
}
.custom-radio input {
  display: none;
}
.custom-radio input:checked + .radio-btn {
  background-color: #262626;
  box-shadow: 0 0 10px white;
  transform: scale(102%);
}
.radio-btn {
  margin: 10px;
  width: 150px;
  height: 160px;
  border: 2px solid white;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: ease-in 50ms;
}
.radio-btn:hover {
  box-shadow: 0 0 10px white;
  transform: scale(102%);
}
.radio-btn .effect-icon {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio-btn h3 {
  margin: 0;
}
.open {
  opacity: 1 !important;
  transform: translateX(0);
}
.closed {
  transform: translateX(-100%);
}
.entering {
  transform: translateX(100%);
}
button {
  color: black;
  background-color: white;
  padding: 10px;
  border-radius: 5rem;
  border: none;
  margin: 1rem;
  font-weight: bold;
  transition: ease-in 100ms;
}
button:hover {
  cursor: pointer;
  transform: translateY(-2px);
}
.location-select h2 {
  padding-bottom: 20px;
}
.location-select select {
  padding: 10px 20px;
  border-radius: 5rem;
}
.card {
  background-color: black;
  border: 1px solid white;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: clamp(200px, 60%, 690px);
  height: fit-content;
  margin: auto;
  text-align: center;
  transition: all 250ms ease-in;
  opacity: 0;
}
h2 {
  margin: 100;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 0.25em;
}

.form-group:last-child {
  margin: 0;
}

.form-group > label {
  font-weight: bold;
  font-size: 0.8em;
  color: #333;
}

.form-group > input {
  border: 1px solid #333;
  border-radius: 0.25em;
  font-size: 1rem;
  padding: 0.25em;
}

.step-title {
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.card.active {
  animation: slide 250ms 125ms ease-in-out both;
}

.multi-step-form {
  overflow: hidden;
  position: relative;
}

.hide {
  display: none;
}

@keyframes slide {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.75);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
