.container{
  grid-template-columns: 1fr 1fr .5fr;
    display: grid;
  width: 100%;
  border: 1px solid lightgray;
  margin-bottom: 10px;
}
.title{
  display: inline;
  font-size: 1rem;
}
.price{
  margin-left: 1rem;
  display: inline;
  color: darkgray;
}
li{
  list-style: none;
}
.container p{
  margin-left: 20px;
}
